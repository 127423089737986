// AccZusatzdateien.js
import React from 'react';
import { Accordion, Row, Col } from 'react-bootstrap';
import ContDescr from "../../basic-elements/comp-cont-descr";
import FormFileSelectVAR from '../form-file-var';

const AccZusatzdateien = ({ imgnaming, varfileDispatch, varfileState, countarrayState }) => {
  return (
    <Accordion>
      <Accordion.Item eventKey="5">
        <Accordion.Header>
          <h2>Zusätzliche Dateien</h2>
        </Accordion.Header>
        <Accordion.Body>
          <Row>
            <ContDescr>
              Hinterlege hier weitere Dateien für deine Abgabe. Beachte hierbei
              die individuellen Vorgaben der Lehrenden.
            </ContDescr>
            <Col>
              <FormFileSelectVAR
                amount_input_field={1}
                label="Datei"
                name="file"
                imgnaming={imgnaming}
                dispatch={varfileDispatch}
                varfileState={varfileState}
                enableAddButton={true}
                countarrayState={countarrayState}
                
              />
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AccZusatzdateien;
