// src/functions/generateExportNaming.js

import { transformModuleValue } from "./formik/filename-value"; // Import the externalized function
import {
    selecStg,
    selecStgType,
    selecSemType,
    getCustomValueofOptionList,
  } from "./const-main";


export function generateExportNaming(values) {
    const customSemestertype = getCustomValueofOptionList(
        values.semestertype,
        selecSemType
      );
      const customselecStgType = getCustomValueofOptionList(values.selecStgType.value, selecStgType) || "default";
      const customSelectStg = getCustomValueofOptionList(values.selecStg.value, selecStg) || "custom";


      console.log("Custom selecStgType:", customselecStgType); // Log customselecStgType
      const transformedModule = transformModuleValue(values.modul);
  
      const namesConcat = values.persondata
        .map((person) => {
          let vorname = person.vorname || ""; // Default to empty string if undefined
          let nachname = person.nachname || ""; // Default to empty string if undefined
          if (values.persondata.length > 2) {
            vorname = vorname.substring(0, 3); // Only apply substring if non-empty
            nachname = nachname.substring(0, 3);
          }
          return `${vorname}${nachname}`;
        })
        .join("-");
  
      // Use values.projektart.value for the filename generation
      return `${values.jahr}${customSemestertype}-${customSelectStg}-${customselecStgType}_${transformedModule}_${namesConcat}_${values.projektart?.value || ""}`;
    };
  