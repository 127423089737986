export async function processCountArray(jsonContent, countarrayDispatch, setFieldValue) {
  const maxCounts = {};

  console.log("Processing JSON content for input counts:", jsonContent);

  // Accumulate max counts for each field
  for (const field in jsonContent) {
    if (field.endsWith("_inputCount")) {
      const baseFieldName = field.replace("_inputCount", "");
      const count = parseInt(jsonContent[field], 10);
      console.log(`Found input count for field "${field}":`, count);
      if (!isNaN(count)) {
        maxCounts[baseFieldName] = count;
      }
    }
  }

  console.log("Max counts to be processed:", maxCounts);

  // Dispatch max counts and update Formik
  for (const baseFieldName in maxCounts) {
    const count = maxCounts[baseFieldName];
    console.log(`Dispatching count for "${baseFieldName}_inputCount":`, count);
    countarrayDispatch({
      type: "SET_MAX_INPUT_COUNT",
      fieldName: `${baseFieldName}_inputCount`, // Update for correct key
      count,
    });
    setFieldValue(`${baseFieldName}_inputCount`, count); // Update Formik
    console.log(`Formik field "${baseFieldName}_inputCount" updated with count:`, count);
  }
}
