import React from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";

const ModalExport = ({ show, onHide, downloadLink, values = {}, imgState = {} }) => {
  const {
    projektart = {},
    persondata = [],
    assignedLehrende = [],
    semestertype = {},
    jahr = '',
    selecStgType = '',
    selecStg = {},
    ger_project_tags = [],
    eng_project_tags = [],
    ger_descr_projekt_short = '',
    eng_descr_projekt_short = '',
    ger_descr_projekt_long = '',
    eng_descr_projekt_long = ''
  } = values;

  const renderManifest = () => {
    if (!imgState || Object.keys(imgState).length === 0) {
      return <p>No files uploaded yet.</p>;
    }

    return (
      <div>
        {/* <h4>Uploaded Files</h4> */}
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {Object.entries(imgState).map(([key, value]) => {
            const { filename, metadata, url, newFilename } = value || {};
            const resolution = metadata
              ? `${metadata.width || "N/A"} x ${metadata.height || "N/A"}`
              : "Resolution not available";

            return (
              <li key={key} style={{ marginBottom: "15px" }}>
                {/* <Row
                  className="align-items-center"
                  style={{
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Col md={2} style={{ textAlign: "center" }}>
                    {url ? (
                      <img
                        src={url}
                        alt={`Preview for ${key}`}
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          border: "1px solid grey",
                          borderRadius: "5px",
                        }}
                      />
                    ) : (
                      <div style={{ fontStyle: "italic", color: "grey" }}>No image available</div>
                    )}
                  </Col>
                  <Col md={8}>
                    <p><strong>Original Filename:</strong> {filename || "No file selected"}</p>
                    <p><strong>New Filename:</strong> {newFilename || "Not available"}</p>
                    <p><strong>Resolution:</strong> {resolution}</p>
                  </Col>
                </Row> */}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      backdrop="static"
      centered
      keyboard={false}
      size="lg" // Use "lg" or "xl" for larger modal
    >
      <Modal.Header closeButton>
        <Modal.Title>Export Complete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Dein Export ist abgeschlossen. Die ZIP Datei wurde heruntergeladen. Überprüfe deinen Download Ordner.</p>
        <div dangerouslySetInnerHTML={{ __html: ger_descr_projekt_long }} />
        {renderManifest()}
        {downloadLink && (
          <a
            href={downloadLink}
            download="exported_file.zip"
            className="btn btn-primary"
          >
            Download File
          </a>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalExport;
