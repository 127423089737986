import React, { useEffect, useState } from 'react';
import { Button, FormGroup, FormLabel, Row, Col } from "react-bootstrap";
import { useFileHandler } from '../../functions/formik/useFileHandler';
import { useFormikContext } from 'formik';
import useUpdateFilenames from '../../functions/hooks/react-useupdateFilenames';
import FormCompButtonAddRemove from "../formik/form-button-add";

const FormFileSelectVAR = ({
  amount_input_field,
  enableAddButton,
  countarrayState,
  label,
  name,
  dispatch,
  varfileState,
  onChange,
  imgnaming,
  cropmark,
  desiredWidth,
  desiredHeight
}) => {
  const { handleFileChange, fileInfo, errorMessage } = useFileHandler(name, cropmark, desiredWidth, desiredHeight, dispatch);
  const [inputCount, setInputCount] = useState(amount_input_field);
  const { values, setFieldValue } = useFormikContext();

  const restrictedExtensions = [
    "exe", "bat", "cmd", "com", "msi", "scr", "ps1", "sh", "bash", "zsh", "csh",
    "pkg", "dmg", "app", "bin", "run", "js", "vbs", "jse", "wsf", "wsh", "py",
    "rb", "pl", "lnk", "jar", "html", "htm", "php"
  ];

  useEffect(() => {
    const key = `${name}_inputCount`;
    dispatch({
      type: 'SET_MAX_INPUT_COUNT', // Dispatch action to countarrayReducer
      fieldName: key, // Use the key for the field
      count: inputCount, // Current input count
    });
    setFieldValue(key, inputCount); // Sync the count with Formik
  }, [inputCount, dispatch, name, setFieldValue]); // Trigger when `inputCount` changes

  
  // Sync with `countarrayState` and Formik
  useEffect(() => {
    const key = `${name}_inputCount`;
    const newCount = countarrayState?.[key];
    if (newCount !== undefined) {
      setFieldValue(key, newCount); // Sync with Formik
      setInputCount(newCount); // Update local state
    }
  }, [countarrayState, name, setFieldValue]); // Trigger on `countarrayState` updates

  // Dispatch input count updates to the reducer
  useEffect(() => {
    const key = `${name}_inputCount`;
    dispatch({
      type: 'SET_MAX_INPUT_COUNT',
      fieldName: key,
      count: inputCount,
    });
  }, [inputCount, dispatch, name]);

  useUpdateFilenames({ name, inputCount, state: varfileState, setFieldValue, imgnaming });

  const addInputField = () => {
    setInputCount((currentCount) => currentCount + 1);
  };

  const removeInputField = () => {
    setInputCount((currentCount) => Math.max(currentCount - 1, 1));
  };

  const handleFileInputChangeFile = (event, fieldName) => {
    const file = event.currentTarget.files[0];
    if (file) {
      const fileExt = file.name.split('.').pop().toLowerCase();
  
      if (restrictedExtensions.includes(fileExt)) {
        alert(`Der ausgewählte Dateityp ".${fileExt}" ist aufgrund von Sicherheitsgründen nicht erlaubt.`);
        event.target.value = ""; // Clear invalid input
        return;
      }
  
      const fileUrl = URL.createObjectURL(file);
      dispatch({ type: 'SET_VAR_FILE_URL', fieldName, varUrl: fileUrl });
  
      const newFilename = `${imgnaming}_${fieldName}.${fileExt}`;
      setFieldValue(`${fieldName}_filename`, newFilename);
      setFieldValue(fieldName, file);
      setFieldValue(`${name}_inputCount`, inputCount); // Sync inputCount with Formik
    }
  };
  

  const inputFields = () => {
    const fields = [];
    for (let i = 1; i <= inputCount; i++) {
      const currentName = `${name}_${i}`;
      const fileData = varfileState[currentName] || {};

      fields.push(
        <Row key={currentName} style={{ marginBottom: '20px' }}>
          <Col md="8">
            <div style={{ minWidth: '100%', backgroundColor: '#ededed', padding: '10px', border: "1px solid grey" }}>
              <FormGroup className="formgroup">
                {label && (
                  <FormLabel htmlFor={currentName}>
                    {`${label} ${i}`}
                  </FormLabel>
                )}
                <input
                  id={currentName}
                  name={currentName}
                  type="file"
                  accept="*/*"
                  onChange={(event) => handleFileInputChangeFile(event, currentName)}
                  style={{ display: "none" }}
                />
                <Button as="label" htmlFor={currentName} className="btn_fileselect_select_pdf">
                  Datei auswählen
                </Button>
                <Row>
                  <Col md="auto">
                    <h4>Dateibenennung</h4>
                  </Col>
                  <Col>
                    <input
                      type="text"
                      name={`${currentName}_filename`}
                      className="form-text-info"
                      value={fileData.isFileSelected ? values[`${currentName}_filename`] : ""}
                      readOnly
                      style={{ userSelect: "none", cursor: "default" }}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </div>
            {errorMessage && <div className="warn-no-crop">{errorMessage}</div>}
          </Col>
          <Col>
            {fileData.url ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <img
                  src="./img/icons/icon_file.svg"
                  style={{ height: '150px', width: '150px' }}
                  alt="File Icon"
                />
              </div>
            ) : (
              <div className='form_file_pdf_noPDF'>Noch keine Datei ausgewählt</div>
            )}
          </Col>
        </Row>
      );
    }
    return fields;
  };

  return (
    <>
      {inputFields()}
      {enableAddButton && (
        <FormCompButtonAddRemove
          onAdd={addInputField}
          onRemove={removeInputField}
          addStyle={{ marginRight: "10px", marginTop: '20px', marginBottom: '20px' }}
          removeStyle={{ marginLeft: "5px" }}
        />
      )}
      <input
        type="text"
        name={`${name}_inputCount`}
        value={inputCount}
        readOnly
        style={{ display: "none" }}
      />
      <hr />
    </>
  );
};

export default FormFileSelectVAR;
