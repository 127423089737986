import JSZip from "jszip";

export const handleFileInputChange = async (
  event,
  setFieldValue,
  setTouched,
  setManualTrigger,
  setFormValues,
  formValues,
  imgDispatch,
  processImageFiles,
  processPDFFiles,
  processVarFiles,
  processCountArray,
  pdfDispatch,
  varfileDispatch,
  countarrayDispatch
) => {
  const file = event.target.files[0];
  console.log("Selected file:", file);

  if (file) {
    try {
      const zip = new JSZip();
      const content = await zip.loadAsync(file);
      const fileKeys = Object.keys(content.files);
      console.log("Processing ZIP file with keys:", fileKeys);

      let jsonContent = null;

      // Extract JSON content from the ZIP file
      for (const key of fileKeys) {
        console.log(`File Key: ${key}`);
        if (key.endsWith(".json")) {
          const jsonString = await content.files[key].async("string");
          jsonContent = JSON.parse(jsonString);
          console.log("Parsed JSON content:", jsonContent);
          break;
        }
      }

      if (jsonContent) {
        const touchedFields = {};
        const newFormValues = { ...formValues };

        // Initialize accordions if missing
        if (!newFormValues.accordion1) newFormValues.accordion1 = {};
        if (!newFormValues.accordion2) newFormValues.accordion2 = {};

        // Update fields and mark them as touched
        for (const field in jsonContent) {
          if (jsonContent[field] !== undefined) {
            setFieldValue(field, jsonContent[field], true);
            touchedFields[field] = true;
            console.log(`Set Formik field: ${field} to value: ${jsonContent[field]}`);

            if (newFormValues.accordion1.hasOwnProperty(field)) {
              newFormValues.accordion1[field] = jsonContent[field];
            } else if (newFormValues.accordion2.hasOwnProperty(field)) {
              newFormValues.accordion2[field] = jsonContent[field];
            } else {
              newFormValues[field] = jsonContent[field];
            }
          }
        }

        console.log("Updated formValues:", newFormValues);

        // Update Formik states
        setTouched(touchedFields); // Correctly mark fields as touched
        setFormValues(newFormValues); // Update form values
        setManualTrigger((prev) => !prev); // Trigger re-render

        // Process files
        await processImageFiles(fileKeys, jsonContent, content, setFieldValue, imgDispatch);
        await processPDFFiles(fileKeys, jsonContent, content, setFieldValue, pdfDispatch);
        await processVarFiles(fileKeys, jsonContent, content, setFieldValue, varfileDispatch);
        await processCountArray(jsonContent, countarrayDispatch, setFieldValue);
        
        // Force re-render after all updates
        setTimeout(() => {
          console.log("[handleFileInputChange] Forcing UI re-render after state updates...");
          setManualTrigger((prev) => !prev);
        }, 50); // Schedule the re-render for the next tick
      }
    } catch (error) {
      console.error("Error processing ZIP file:", error);
    }
  }
};
