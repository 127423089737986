// src/functions/handleSubmit.js
import { generateExportNaming } from "../func-generateExportNaming.js";
import { getUnixTimestamp } from "../func-getunixtimestamp.js";
import { parseTimestamp, parseTimestampFolder, } from "../const-seafile.js";
import { formatHtml } from "../handlebar/handlebarTemplateUtils.js";
import { fetchAndProcessTemplate } from "../func-fetchAndProcessTemplate.js";
import TemplateInsta from '../../components/templates/temp-insta.js'; // Import the new template component
import TemplateYoutube from "../../components/templates/temp-youtube.js";

import JSZip from "jszip";

export async function abgahandleSubmit(
  values,
  setValues,
  setModalShow,
  setDownloadLink
) {
  const ZIPfilename = generateExportNaming(values);
  const zip = new JSZip();

  // Dynamically find and process all relevant file fields
  Object.keys(values).forEach((key) => {
    if (values[key] instanceof File) {
      const fileField = key;
      const filenameField = `${fileField}_filename`;
      const file = values[fileField];

      // Extract the file extension from the file name
      const fileExt = file.name.split(".").pop();
      let index = fileField.match(/_\d+$/) ? "" : "_1"; // Add _1 if no index exists
      // Remove any existing file extension
      const cleanFileName = values[filenameField]
        ? values[filenameField].replace(/\.\w+$/, "")
        : `${ZIPfilename}_${fileField}${index}`;

      // Append the correct file extension
      let newFileName = `${cleanFileName}.${fileExt}`;


      zip.file(newFileName, file);

      if (!values[filenameField] || !values[filenameField].endsWith(fileExt)) {
        values[filenameField] = newFileName; // Update filename only if necessary
      }

            zip.file(newFileName, file);
            values[filenameField] = newFileName; // Update filename field to ensure correct extension
          }
        });

  // Combine the existing values with additional data
  const timestamp = getUnixTimestamp();
  const combinedData = {
    ...values,
    timestamp: timestamp,
    parsed_timestamp: parseTimestamp(timestamp),
    filename: `${ZIPfilename}_${timestamp}`,
  };

  // Stringify the combined data
  const jsonData = JSON.stringify(combinedData, null, 2);

  // Add the JSON file with form data to the ZIP
  zip.file(`${ZIPfilename}_data.json`, jsonData);

  // Register the Handlebars helper for formatting HTML
  const Handlebars = require("handlebars");
  Handlebars.registerHelper("formatHtml", formatHtml);

  // Define the templates to fetch and process
  const templates = {
    metadata_template: "./templates/metadata-template.hbs",
  };

   // Show the modal
  //  setModalShow(true);

  // Conditionally include additional templates based on form values
  Object.assign(
    templates,
    values["check_youtube_allowance"]
      ? { TemplateYoutube: "react-youtube-template" }
      : { "DO-NOT-PUBLISH-ON-YOUTUBE": "./templates/DO-NOT-PUBLISH-ON-YOUTUBE.hbs" },
    values["url_insta_allowance"]
      ? { TemplateInsta: "react-insta-template" }
      : {}
  );

  // Fetch and process all templates
  await Promise.all(
    Object.keys(templates).map((templateKey) =>
      fetchAndProcessTemplate(
        templateKey,
        templates,
        values,
        zip,
        ZIPfilename,
        TemplateInsta,
        TemplateYoutube,
        formatHtml
      )
    )
  );

  // Generate ZIP file and trigger download
  zip
    .generateAsync({ type: "blob" })
    .then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${ZIPfilename}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      const downloadURL = URL.createObjectURL(blob);
      setModalShow(true); // Show the modal
      setDownloadLink(downloadURL); // Pass URL to the modal
      
    })
    .catch((error) => {
      console.error("Error creating ZIP file:", error);
    });
}
