import React, { useEffect } from 'react';
import { useRive, useStateMachineInput } from '@rive-app/react-canvas';

function Rivepackageanimation({
  src,
  stateMachine,
  hoverState,
  clickState,
  style,
  externalHover,
  onClick, // New prop to pass parent click handler
}) {
  const { RiveComponent, rive } = useRive({
    src,
    stateMachines: stateMachine,
    autoplay: true,
  });

  const hoverInput = useStateMachineInput(rive, stateMachine, hoverState);
  const clickInput = useStateMachineInput(rive, stateMachine, clickState);

  useEffect(() => {
    if (hoverInput) {
      hoverInput.value = externalHover; // Set hover input
    }
  }, [externalHover, hoverInput]);

  const handleRiveClick = () => {
    if (clickInput) {
      clickInput.fire(); // Trigger click input
      console.log('Click input fired in Rive');
    } else {
      console.error('Click input not found in the state machine.');
    }

    if (onClick) {
      onClick(); // Call parent click handler
    }
  };

  return <RiveComponent style={style} onClick={handleRiveClick} />;
}

export default Rivepackageanimation;
